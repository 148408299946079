
import { defineComponent, inject, reactive, ref, computed, onBeforeMount, Ref } from 'vue'
import { IonPage, IonContent, IonCheckbox } from '@ionic/vue'
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import TermsAndConditions from '@/components/termsAndConditions.vue'
import i18n from '@/i18n';
import { Storage } from '@capacitor/storage';

export default defineComponent({
    components: {
        IonPage, 
        IonContent, 
        IonCheckbox,
        TermsAndConditions
    },
    setup() {
        const authStore: any = inject('authState');
        const configurationStore: any = inject('configurationStore');
        const uniqueValidationFormData = reactive(configurationStore.state.uniqueValidationFormData);
        const validPrefixList = computed(() => authStore.state.validPrefixList)
        const router: any = useRouter()
        const tos: Ref<boolean> = ref(false)
        const showTerms: Ref<boolean> = ref(false)
        const emailSuccess: Ref<boolean> = ref(false)
        const mobileSuccess: Ref<boolean> = ref(false)
        const emailCodeSuccess: Ref<boolean> = ref(false)
        const mobileCodeSuccess: Ref<boolean> = ref(false)
        const termsConditionsSuccess: Ref<boolean> = ref(false)
        const goTo = (url: any) =>{
            router.push( { name: url } );
        }
        onBeforeMount(async () => {
            await authStore.obtainCountry()
            setDefaultValues();
        })
        const validateEmail = async () => {
            const data = {
                newemail: configurationStore.state.uniqueValidationFormData.emailData.email
            }
            emailCodeSuccess.value = false
            configurationStore.state.uniqueValidationFormData.emailData.validationCode = ''
            emailSuccess.value = await configurationStore.validateUniqueEmail(data)
        }
        const validateMobile = async () => {
            configurationStore.state.uniqueValidationFormData.mobileData.input.showError = false;
            if (configurationStore.state.uniqueValidationFormData.mobileData.number.length != 9) {
                configurationStore.state.uniqueValidationFormData.mobileData.input.showError = true;
                configurationStore.state.uniqueValidationFormData.mobileData.input.errorCode = 'tester_mobile_incorrect';
            } else {
                const data = {
                    prefix: configurationStore.state.uniqueValidationFormData.mobileData.prefix,
                    number: configurationStore.state.uniqueValidationFormData.mobileData.number
                }
                mobileCodeSuccess.value = false
                configurationStore.state.uniqueValidationFormData.mobileData.validationCode = ''
                mobileSuccess.value = await configurationStore.validateUniqueMobile(data)
            }
        }
        const verifyEmailCode = async () => {
            const data = {
                validationCode: configurationStore.state.uniqueValidationFormData.emailData.validationCode
            }
            emailCodeSuccess.value = await configurationStore.verifyUniqueEmailCode(
                authStore.state.userData.identifier,
                data
            )
        }
        const verifyMobileCode = async () => {
            const data = {
                validationCode: configurationStore.state.uniqueValidationFormData.mobileData.validationCode
            }
            mobileCodeSuccess.value = await configurationStore.verifyUniqueMobileCode(
                authStore.state.userData.identifier,
                data
            )
        }
        const acceptTermsConditions = async () => {
            termsConditionsSuccess.value = await configurationStore.confirmTermsConditions(
                authStore.state.userData.identifier
            );
        }
        const uniqueFormSubmit = async () => {
            configurationStore.state.uniqueValidationFormData.emailData.showError = false;
            configurationStore.state.uniqueValidationFormData.mobileData.showError = false;
            await authStore.getUserDetails();
            Swal.fire({
                icon: 'success',
                title: i18n.global.t('tester_unique_validated'),
                showConfirmButton: true,
                confirmButtonText: i18n.global.t('Accept'),
            }).then((result)=>{
                if (result.isConfirmed || result.dismiss === Swal.DismissReason.esc || result.dismiss === Swal.DismissReason.backdrop) {
                    redirect();
                }
            })
        }
        const redirect = async () => {
            const needs2fa = await Storage.get({key:'needs2FA'})
            if (needs2fa.value) {
                goTo('validate2fa')
            } else {
                goTo('tests.index')
            }
        }
        const setErrorMessage = (errorCode: any) => {
            switch (errorCode) {
                case 'code_not_correct':
                    return i18n.global.t('tester_pin_code_error');
                case 'tester_mobile_incorrect':
                    return i18n.global.t('tester_mobile_incorrect');
                case 'registration_mobile_fraud':
                    return i18n.global.t('tester_duplicate_mobile_error');
                case 'default_error_message':
                default:
                    return i18n.global.t('tester_default_error');
            }
        }
        const handleShowTerms = () => {
            showTerms.value = false
        }
        const setDefaultValues = async() => {
            uniqueValidationFormData.mobileData.prefix = authStore.state.userData.mobilePrefix
            uniqueValidationFormData.mobileData.number = authStore.state.userData.mobileNum
            uniqueValidationFormData.emailData.email = authStore.state.userData.email
        }
        const uniqueFormValid = computed(() => {
            return emailCodeSuccess.value && mobileCodeSuccess.value && tos.value && termsConditionsSuccess.value;
        });
        return{
            uniqueValidationFormData,
            uniqueFormSubmit,
            validateEmail,
            validateMobile,
            goTo,
            validPrefixList,
            uniqueFormValid,
            showTerms,
            handleShowTerms,
            tos,
            emailSuccess,
            mobileSuccess,
            verifyEmailCode,
            verifyMobileCode,
            mobileCodeSuccess,
            emailCodeSuccess,
            setErrorMessage,
            acceptTermsConditions,
            termsConditionsSuccess,
            redirect
        }
    }
})
